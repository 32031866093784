<template>
  <div>
    <SEARCH :searchChange="onCallbackSearch" :list="searchArr"></SEARCH>
    <TABS :list="tabsArr"></TABS>
    <TABLE
      :switchChange="onCallbackSwitch"
      :buttonChange="onCallbackButton"
      :isShowSele="false"
      :list="tableArr"
      :data="dataArr"
    ></TABLE>
    <PAGE
      :pageChange="onCallbackPage"
      :limitChange="onCallbackLimit"
      :count="count"
    ></PAGE>
  </div>
</template>

<script>
import SEARCH from '@/components/search' // 顶部搜索
import TABS from '@/components/tabs' // 横向 button
import TABLE from '@/components/table' // 列表
import PAGE from '@/components/page' // 分页
import { getRules, updRules, updRulesAuth, delRules } from '@/api/system'
export default {
  name: 'SystemAuthManageAuthRules',
  components: {
    SEARCH,
    TABS,
    TABLE,
    PAGE,
  },
  data() {
    return {
      searchArr: [
        {
          name: '状态',
          type: 'picker',
          options: [],
          key: 'type',
        },
        {
          name: '功能名称',
          type: 'input',
          options: [],
          key: 'name',
        },
      ], // 搜索列表
      tabsArr: [
        {
          type: 'primary',
          name: '添加规则',
          func: '',
        },
      ], // 标签列表
      tableArr: [
        {
          prop: 'admin_menu_id',
          label: 'ID',
          width: '200',
        },
        {
          prop: 'menu_name',
          label: '功能名称',
          width: '200',
        },
        {
          prop: 'menu_url',
          label: '链接',
          width: '320',
        },
        {
          prop: 'menu_sort',
          label: '排序',
          width: '200',
        },
        {
          prop: 'is_disable',
          label: '状态',
          width: '200',
          type: 'switch',
        },
        {
          prop: 'is_unauth',
          label: '权限',
          width: '100',
          type: 'switch',
        },
        {
          prop: '',
          label: '操作',
          width: '',
          type: 'but',
        },
      ], // 表头列表
      dataArr: [], // 数据列表
      butnArr: [
        {
          type: '',
          name: '添加',
          func: '',
        },
        {
          type: '',
          name: '角色',
          func: '',
        },
        {
          type: '',
          name: '用户',
          func: '',
        },
        {
          type: '',
          name: '编辑',
          func: '',
        },
        {
          type: 'danger',
          name: '删除',
          func: 'removeRules',
        },
      ], // 数据-操作列表
      count: 0, // 总页数
      formData: {
        page: 1,
        limit: 10,
        name: '', // 姓名
        type: '', // 账号
      },
    }
  },
  created() {},
  mounted() {
    this._loadData()
  },
  methods: {
    _loadData: function () {
      getRules(this.formData).then((res) => {
        const { result } = res

        this.count = result.count
        result.list.forEach((item) => {
          item.buttons = JSON.parse(JSON.stringify(this.butnArr))
          item.is_unauth = {
            status: item.is_unauth === 1,
            func: 'changeAuth',
          }
          item.is_disable = {
            status: item.is_disable === 1,
            func: 'changeStatus',
          }
        })
        this.dataArr = result.list
      })
    },

    // 修改角色状态
    changeStatus: function ({ admin_menu_id: id, is_disable: disable }) {
      const data = {
        admin_menu_id: id,
        is_disable: disable === 1 ? 0 : 1,
      }
      updRules(data).then((res) => {
        this.$toast(res.message)
        this._loadData()
      })
    },
    // 修改权限
    changeAuth: function({ admin_menu_id: id, is_unauth: unauth }) {
      const data = {
        admin_menu_id: id,
        is_unauth: unauth === 1 ? 0 : 1,
      }
      updRulesAuth(data).then((res) => {
        this.$toast(res.message)
        this._loadData()
      })
    },

    // 删除管理员
    removeRules: function ({ admin_menu_id: id }) {
      delRules(id).then((res) => {
        this.$toast(res.message)
        this._loadData()
      })
    },

    // 收到搜索回调
    onCallbackSearch: function (data) {
      const { name, type } = data
      this.formData.type = type
      this.formData.name = name
      this._loadData()
    },

    // 收到 table内方法 回调
    onCallbackButton: function (item, func) {
      this[func](item)
    },
    // 收到 表格内 switch 方法回调
    onCallbackSwitch: function (item, func) {
      this[func](item)
    },

    // 收到改变页码回调
    onCallbackPage: function (page) {
      this.formData.page = page
      this._loadData()
    },
    // 收到改变一页数量回调
    onCallbackLimit: function (limit) {
      this.formData.limit = limit
      this._loadData()
    },
  },
}
</script>

<style lang="stylus" scoped></style>
